import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../../../data/featureFlags";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import InsightsActions from "../../../store/insights/InsightsActions";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { STATIC_INSIGHTS_TABS, SUMMARY_TAB } from "./InsightsTabs.constants";
import { SubpageTab } from "./InsightsTabs.types";

export const useInsightsTabs = () => {
    const dispatch = useDispatch();
    const activeTab = useSelector(InsightsSelectors.getSelectedTab);
    const isSummaryEnabled = useFeatureFlag(featureFlags.kpiSummary);

    const tabs: SubpageTab[] = useMemo(() => {
        let updatedTabs = [...STATIC_INSIGHTS_TABS];

        if (isSummaryEnabled) {
            updatedTabs = [SUMMARY_TAB, ...updatedTabs];
        }

        return updatedTabs;
    }, [isSummaryEnabled]);

    const setActiveTab = (tabIndex: number) => dispatch(InsightsActions.setSelectedTab(tabIndex));

    return {
        activeTab,
        activeTabLabelKey: tabs?.[activeTab]?.labelKey,
        setActiveTab,
        tabs,
    };
};
