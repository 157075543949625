import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { formatDateTime } from "src/helpers/date";
import { ChildrenProps } from "src/models/ChildrenPropModel";
import { flexColumn } from "src/styles/templates/Flex";
import styled from "styled-components";

import { Tag, TagProps } from "./SharedComponents/Tag";

export type Direction = "row" | "column";
export type ValueAlignment = "start" | "end";

const TextValue = styled(Text)<{ alignment?: ValueAlignment }>`
    ${(props) => props.alignment && `align-self: ${props.alignment}`};
`;

export const DefaultItemFieldWrapper = styled.div`
    container-type: inline-size;
    container-name: DefaultItemFieldWrapper;
    ${flexColumn}
    padding: ${Spacing.S}px ${Spacing.M}px;
    border-radius: ${Spacing.XXS}px;
    background-color: ${Palette.Navy650};

    margin-bottom: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.XS}px ${Spacing.S}px;
        margin-bottom: ${Spacing.XXS}px;
    }

    @media (min-width: ${Breakpoints.XS}) and (max-width: ${Breakpoints.M}) {
        padding: ${Spacing.XS}px ${Spacing.S}px;
        margin-bottom: ${Spacing.XS}px;
    }
`;

const Item = styled(Stack)`
    ${TextValue} {
        ${(props) => props.direction === "row" && "text-align: end;"};
    }
`;

const DefaultItemLabel = styled(Text)`
    flex: 1 1 0;
    width: fit-content;
    margin-right: ${Spacing.XL}px;

    @media (max-width: ${Breakpoints.XS}) {
        margin-right: ${Spacing.XS}px;
    }
`;

const DateLine = styled.div`
    @media (max-width: ${Breakpoints.XS}) {
        display: flex;
        flex-direction: column;
    }
`;

const StyledDateRangeText = styled(Text)`
    color: ${Palette.Navy100};
    margin-right: ${Spacing.XS}px;
    text-align: end;

    @media (max-width: ${Breakpoints.XS}) {
        text-align: start;
    }
`;

const StyledDateText = styled(Text)`
    text-align: right;
    &:not(:last-child) {
        margin-right: ${Spacing.S}px;
    }
`;

export const TextValueDisplay: React.FC<{ value: string; valueAlignment?: ValueAlignment }> = ({ value, valueAlignment }) => (
    <TextValue alignment={valueAlignment}>{value}</TextValue>
);

export const DateRangeField: React.FC<{
    dateFrom?: Date | string;
    dateTo?: Date | string;
    direction?: Direction;
}> = ({ dateFrom, dateTo, direction = "row" }) => {
    const { t } = useTranslation();
    const dateValue = (date: Date | string) => {
        if (!date) {
            return t("common.noDataFound");
        }
        return typeof date === "string" ? date : formatDateTime(date);
    };
    return (
        <Stack>
            {direction === "row" && (
                <>
                    <StyledDateRangeText>{t("common.from")}</StyledDateRangeText>
                    <StyledDateText>{dateValue(dateFrom)}</StyledDateText>
                    <StyledDateRangeText>{t("common.to")}</StyledDateRangeText>
                    <StyledDateText>{dateValue(dateTo)}</StyledDateText>
                </>
            )}
            {direction === "column" && (
                <>
                    <DateLine>
                        <StyledDateRangeText>{t("common.from")}</StyledDateRangeText>
                        <StyledDateRangeText>{t("common.to")}</StyledDateRangeText>
                    </DateLine>
                    <DateLine>
                        <StyledDateText>{dateValue(dateFrom)}</StyledDateText>
                        <Text>{dateValue(dateTo)}</Text>
                    </DateLine>
                </>
            )}
        </Stack>
    );
};

type DefaultItemFieldProps = ChildrenProps & {
    label: string;
    tag?: TagProps;
    direction?: Direction;
    alignItems?: "baseline" | "center" | "flex-end" | "flex-start" | "stretch";
};

export const DefaultItemField = (props: DefaultItemFieldProps) => (
    <DefaultItemFieldWrapper data-testid="default-item-field">
        {props.tag?.text && <Tag text={props.tag.text} icon={props.tag.icon} />}
        <DefaultItemRow {...props} />
    </DefaultItemFieldWrapper>
);

export const DefaultItemRow = ({ label, direction = "row", children, alignItems }: DefaultItemFieldProps) => (
    <Item direction={direction} flex={1} alignItems={alignItems} justifyContent="space-between">
        <DefaultItemLabel bold>{label}</DefaultItemLabel>
        {children}
    </Item>
);

export const StackField = ({ children }: ChildrenProps) => <DefaultItemFieldWrapper data-testid="default-stack-field">{children}</DefaultItemFieldWrapper>;
