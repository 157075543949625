import { IconType } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { getExceptionQuestionsAnswers } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { TextSummaryField } from "./TextSummaryField";
import { TrueDeviationsDisclaimer } from "./TrueDeviationsDisclaimer";

export const ExceptionQuestionsFields = ({ fieldValue, trueExceptions = "", shouldAutoTranslate = true }) => {
    const { t } = useTranslation();
    const exceptionQuestions = fieldValue ? getExceptionQuestionsAnswers(fieldValue ?? "") : [];
    const trueExceptionQuestions = trueExceptions ? JSON.parse(trueExceptions) : [];
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    if (!fieldValue) return <></>;

    const deviationTag = { text: t("reportlist.deviations"), icon: "Deviations" as IconType };

    return (
        <>
            {isTrueDeviationsEnabled && trueExceptionQuestions.length > 0 && <TrueDeviationsDisclaimer />}
            {exceptionQuestions.map(([question, answer]) => {
                if (question)
                    return (
                        <TextSummaryField
                            key={question}
                            fieldName={question}
                            fieldValue={answer}
                            tag={isTrueDeviationsEnabled && isTrueException(question, trueExceptionQuestions) ? deviationTag : undefined}
                            shouldAutoTranslate={shouldAutoTranslate}
                            desktopDirection="row"
                            mobileDirection="column"
                            mobileValueAlignment="end"
                        />
                    );
            })}
        </>
    );
};

const isTrueException = (question: string, trueExceptions: string[]): boolean => {
    return trueExceptions.includes(question);
};
