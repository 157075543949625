import { DynamicReportType, PatrolTourType, ReportsListItemType } from "src/models/ReportModel";

import { IReportImagesState } from "../../models/ReportImagesModels";
import { ISiteObject } from "../../models/SiteObjectModel";
import { RequestStatus } from "../RequestStatus";

export interface ISharedReportState {
    data?: {
        expiration: string;
        report?: any;
        siteObject: ISiteObject;
    };
    status?: RequestStatus;
}

export interface IReportSearchState {
    page?: number;
    size?: number;
    totalCount?: number;
    reports: ReportsListItemType[];
    status?: RequestStatus;
    refreshing?: boolean;
    timestampRequested?: number;
    requestId?: string;
}

export enum ExportStateEnum {
    loading,
    prefetchSucceded,
    prefetchFailed,
    fetchSucceeded,
    fetchFailed,
    fileCreated,
}

export interface IReportsState {
    currentReport: {
        id: string;
        report: any;
        status: RequestStatus;
        error: unknown;
        relatedReports: any[];
        relatedReportsStatus: {
            status: RequestStatus;
            error: unknown;
            exceptionReportId: string;
        }[];
    };
    error: unknown;
    reportImages: IReportImagesState;
    reportSearchByKey: Record<string, IReportSearchState>;
    totalCount: number;
    dynamicTranslations: Record<
        string,
        {
            translation: string;
            loading: boolean;
            error?: any;
        }
    >;
    autoTranslations: {
        sections: Record<string, { isAnythingAutoTranslated: boolean }>;
    };
    translationLoadingPending?: Record<string, boolean>;
}

export type ExportReportsState = {
    exportState: ExportStateEnum;
    totalCount: number;
    reports: ReportsListItemType[];
    isSuccess?: boolean;
    isError?: boolean;
};

export type ReportsSearchResponse = {
    page: number;
    size: number;
    totalCount: number;
    reports: ReportsListItemType[];
};

export type TranslationTextResponse = {
    detectedLanguage?: {
        language: string;
        score: number;
    };
    translations: {
        alignment: unknown;
        sentLen: unknown;
        text: string;
        to: string;
        transliteration: unknown;
    }[];
};

export type ShareReportRequest = {
    languageCode: string;
    locationId: string;
    recipientEmails: string[];
    reportId: string;
    senderId: string;
};

export type ReportResponse = Partial<PatrolTourType | DynamicReportType> & {
    shareId?: string;
    report?: DynamicReportType;
};
