import { useEffect, useMemo, useState } from "react";
import { formatDate, formatExplicit } from "src/helpers/date";

import { SURVICATE_EVENTS, useSurvicate } from "../../../helpers/survicate";
import { useSummaryPeriod, useSummaryViewMode } from "./shared/hooks";

export const useSummary = () => {
    const periods = useSummaryPeriod();
    const summaryViewMode = useSummaryViewMode();

    const periodLabel =
        summaryViewMode === "month" ? formatExplicit(periods.currentPeriod.start, "MMMM y") : formatDate(periods.currentPeriod.start, "longDate");

    return {
        periodLabel,
    };
};

export const useSurvicateInSummary = () => {
    const [shouldOpenSurvey, setShouldOpenSurvey] = useState(false);
    const survicate = useSurvicate();

    const handleUserInteraction = useMemo(() => (shouldOpenSurvey ? undefined : () => setShouldOpenSurvey(true)), [setShouldOpenSurvey, shouldOpenSurvey]);

    useEffect(() => {
        if (survicate && shouldOpenSurvey) {
            survicate.invokeEvent(SURVICATE_EVENTS.insights.enterSummary);
        }
    }, [survicate, shouldOpenSurvey]);

    return { handleUserInteraction };
};
