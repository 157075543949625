import { createSelector } from "reselect";

import { IState } from "../../Store";

const mapReportImages = ({ reports: { reportImages } }: IState) => reportImages;
const getReportImages = createSelector([mapReportImages], (imageState) => imageState);

const mapExportReportsStore = ({ exportReportsSlice }: IState) => exportReportsSlice;
const getExportReportsStore = createSelector([mapExportReportsStore], (exportReports) => exportReports);

const mapReportsFromSearch = ({ reports: { reportSearchByKey } }: IState) => reportSearchByKey;
const getReportsFromSearch = createSelector([mapReportsFromSearch], (state) => state);

const mapTotalReportsCount = ({ reports: { totalCount } }: IState) => totalCount;
const getTotalReportsCount = createSelector([mapTotalReportsCount], (state) => state);

const mapCurrentReport = ({ reports: { currentReport } }: IState) => currentReport;
const getCurrentReport = createSelector([mapCurrentReport], (state) => state);

const mapDynamicTranslations = ({ reports: { dynamicTranslations } }: IState) => dynamicTranslations;
const getDynamicTranslations = createSelector([mapDynamicTranslations], (state) => state);
const areDynamicTranslationsLoading = createSelector([mapDynamicTranslations], (state) => Object.values(state).some((translation) => translation.loading));

const mapTranslationLoadingPending = ({ reports: { translationLoadingPending } }: IState) => translationLoadingPending;
const getTranslationLoadingPending = createSelector(
    [mapTranslationLoadingPending, (state, id: string) => id],
    (translationLoadingPending, id: string) => translationLoadingPending[id] || false,
);

const mapAutoTranslationsSection = ({ reports: { autoTranslations } }: IState) => autoTranslations;
const getAutoTranslationsState = createSelector([mapAutoTranslationsSection], (autoTranslations) => autoTranslations);
const getAutoTranslationsSectionState = createSelector(
    [mapAutoTranslationsSection, (state, sectionId: string) => sectionId],
    (autoTranslations, sectionId: string) => autoTranslations.sections[sectionId],
);

export default {
    getReportImages,
    getReportsFromSearch,
    getExportReportsStore,
    getTotalReportsCount,
    getCurrentReport,
    getDynamicTranslations,
    areDynamicTranslationsLoading,
    getTranslationLoadingPending,
    getAutoTranslationsState,
    getAutoTranslationsSectionState,
};
