import { keyBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { ItemCallback, Layout, Layouts } from "react-grid-layout";

import { GRID_BREAKPOINTS, GRID_ROW_SIZE } from "./DraggableGrid.constants";
import { getColumnsSetup, prepareResponsiveLayoutConfig } from "./DraggableGrid.helpers";
import { DraggableGridProps } from "./DraggableGrid.types";

export const useDraggableGrid = ({ layout, defaultLayout, widgets, editable, onLayoutChange, heightUnit = GRID_ROW_SIZE, ...props }: DraggableGridProps) => {
    const columns = useMemo(() => getColumnsSetup(props.columns), [props.columns]);
    const placementSettings = useMemo(() => layout ?? defaultLayout, [layout, defaultLayout]);
    const layouts = useMemo(() => prepareResponsiveLayoutConfig(widgets, placementSettings), [placementSettings, widgets]);
    const widgetsMap = useMemo(() => keyBy(widgets, "id"), [widgets]);
    const [currentLayout, setCurrentLayout] = useState<Layout[]>(layouts.lg);
    const [draggedId, setDraggedId] = useState<null | string>(null);

    const handleLayoutChange = useCallback(
        (layout: Layout[], layouts: Layouts) => {
            if (editable) {
                setCurrentLayout(layout);
                //TODO: Handle when should we trigger the outside callback
                // as RGL's onLayoutChange is triggered multiple times
                // even while resizing the window
                onLayoutChange?.(layouts);
            }
        },
        [onLayoutChange, editable],
    );

    const handleDragStart: ItemCallback = (_, oldItem) => {
        setDraggedId(oldItem.i);
    };
    const handleDragStop: ItemCallback = () => {
        setDraggedId(null);
    };

    return {
        columns,
        layouts,
        currentLayout,
        editable,
        handleLayoutChange,
        handleDragStart,
        handleDragStop,
        draggedId,
        widgetsMap,
        rowHeight: heightUnit,
        breakpoints: GRID_BREAKPOINTS,
    };
};
