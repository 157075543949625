import { getFieldValueByFieldName, getSiteLocation } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { TourExceptionFields } from "../../../models/reportTemplates/TourException";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { ExceptionQuestionField } from "../SharedComponents/ExceptionQuestionField";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getTourExceptionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.tourException.comments",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description: getFieldValueByFieldName(TourExceptionFields.comments, report),
                    sharedReportId: sharedReportId,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.detailedInfo.tourExceptionSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            conditionalRender(getFieldValueByFieldName(TourExceptionFields.exceptionQuestion, report), () => ({
                component: ExceptionQuestionField,
                props: {
                    question: getFieldValueByFieldName(TourExceptionFields.exceptionQuestion, report),
                    answer: getFieldValueByFieldName(TourExceptionFields.exceptionAnswer, report),
                    isTrueException: getFieldValueByFieldName(TourExceptionFields.isTrueException, report) === "true",
                },
            })),
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.checkpoint",
                    fieldValue: getFieldValueByFieldName(TourExceptionFields.checkpoint, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(TourExceptionFields)),
        ],
    },
];
