import { Button, Headline, Stack } from "@secuis/ccp-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { featureFlags } from "../../../data/featureFlags";
import { useFeatureFlag } from "../../../hooks/featureFlags";
import { DraggableGrid } from "./DraggableGrid/DraggableGrid";
import { GRID_WIDGETS } from "./Summary.constants";
import { useSummary, useSurvicateInSummary } from "./Summary.hooks";
import { NoWrapSpanStyled } from "./Summary.styles";
import { SummaryGrid } from "./SummaryGrid";
import { ViewModeSwitcher } from "./ViewModeSwitcher";

export const Summary = () => {
    const isCustomizable = useFeatureFlag(featureFlags.personalizeKpiSummary);
    const { t } = useTranslation();
    const { periodLabel } = useSummary();
    const { handleUserInteraction } = useSurvicateInSummary();

    //temp
    const [isEdit, setIsEdit] = useState(false);
    const toggle = () => setIsEdit((prev) => !prev);
    //

    return (
        <div onMouseMove={handleUserInteraction}>
            <Stack mb="S" gap="S" justifyContent="space-between" alignItems="center">
                <Headline data-test-id="summary-headline">
                    {t("insights.summary.title")}: <NoWrapSpanStyled>{periodLabel}</NoWrapSpanStyled>
                </Headline>
                <ViewModeSwitcher />
            </Stack>
            {isCustomizable ? (
                <>
                    {/*{=== Temp ===}*/}
                    <div>
                        <Button mode={isEdit ? "outlined" : "contained"} onClick={toggle}>
                            Toggle grid
                        </Button>
                    </div>
                    {/*{========}*/}
                    <DraggableGrid editable={isEdit} widgets={GRID_WIDGETS} defaultLayout={GRID_WIDGETS} />
                </>
            ) : (
                <SummaryGrid />
            )}
        </div>
    );
};
