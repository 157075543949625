import { RequestStatus } from "../RequestStatus";
import { ExportReportsState, IReportsState } from "./types";

export const REPORTS_INITIAL_STATE: IReportsState = {
    totalCount: 0,
    currentReport: {
        id: null,
        report: null,
        status: RequestStatus.undefined,
        error: undefined,
        relatedReports: [],
        relatedReportsStatus: [],
    },
    error: null,
    reportImages: {
        byImagePath: {},
        loadingMultiple: false,
    },
    reportSearchByKey: {},
    dynamicTranslations: {},
    autoTranslations: {
        sections: {},
    },
    translationLoadingPending: {},
};

export const EXPORT_REPORTS_INITIAL_STATE: ExportReportsState = {
    exportState: null,
    reports: [],
    isSuccess: false,
    isError: false,
    totalCount: 0,
};
