import { createApi } from "@reduxjs/toolkit/query/react";
import * as htmlToImage from "html-to-image";
import { baseQuery } from "src/api/baseQuery";
import { waitForElement } from "src/helpers/DOMHelpers";
import { getImageFromResponse } from "src/helpers/RequestHelper";
import { SearchReportsRequestParams } from "src/models/ReportFilterModels";
import { IImageData, ImageSizePaths } from "src/models/ReportImagesModels";
import { DynamicReportType } from "src/models/ReportModel";

import { parseReportsRequestBody } from "./helpers";
import { ReportResponse, ReportsSearchResponse, ShareReportRequest, TranslationTextResponse } from "./types";

export const reportsApi = createApi({
    reducerPath: "reportsApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        fetchReportsFromSearch: builder.query<
            ReportsSearchResponse,
            {
                pageSize: number;
                pageNumber: number;
                body: SearchReportsRequestParams;
                key: string;
                timestampRequested: number;
                useSiteTimezone?: boolean;
            }
        >({
            query: ({ pageSize, pageNumber, body, useSiteTimezone = false }) => {
                const apiVersion = useSiteTimezone ? "v2" : "v1";

                return {
                    url: `/${apiVersion}/global/reports/search?page=${pageNumber}&size=${pageSize}`,
                    method: "POST",
                    body: parseReportsRequestBody(body, useSiteTimezone),
                };
            },
        }),
        prefetchReportsToExport: builder.query<
            ReportsSearchResponse,
            {
                useSiteTimezone?: boolean;
                body: SearchReportsRequestParams;
            }
        >({
            query: ({ body, useSiteTimezone = false }) => {
                const apiVersion = useSiteTimezone ? "v2" : "v1";

                return {
                    url: `/${apiVersion}/global/reports/search?size=1`,
                    method: "POST",
                    body: parseReportsRequestBody(body, useSiteTimezone),
                };
            },
        }),
        fetchReportsToExport: builder.query<
            ReportsSearchResponse,
            {
                pageSize: number;
                useSiteTimezone?: boolean;
                body: SearchReportsRequestParams;
            }
        >({
            query: ({ pageSize, body, useSiteTimezone = false }) => {
                const apiVersion = useSiteTimezone ? "v2" : "v1";

                return {
                    url: `/${apiVersion}/global/reports/search?size=${pageSize}`,
                    method: "POST",
                    body: parseReportsRequestBody(body, useSiteTimezone),
                };
            },
        }),
        getReport: builder.query<ReportResponse, { reportId: string; siteObjectId: string }>({
            query: ({ reportId, siteObjectId }) => `/v2/global/sites/${siteObjectId}/reports/${reportId}`,
        }),
        getTourSession: builder.query<ReportResponse, { reportId: string; siteObjectId: string }>({
            query: ({ reportId, siteObjectId }) => `/v1/global/sites/${siteObjectId}/tour-sessions/${reportId}`,
        }),
        getSharedReport: builder.query<ReportResponse, { reportId: string }>({
            query: ({ reportId }) => `/v2/global/reports/shared/${reportId}`,
        }),
        getSharedTourSession: builder.query<ReportResponse, { reportId: string }>({
            query: ({ reportId }) => `/v1/global/tour-sessions/shared/${reportId}`,
        }),
        getRelatedReport: builder.query<DynamicReportType, { reportId: string; siteObjectId: string }>({
            query: ({ reportId, siteObjectId }) => `/v2/global/sites/${siteObjectId}/reports/${reportId}`,
        }),
        asyncGeneratePieChart: builder.query<string, void>({
            queryFn: async () => {
                const pieChartElement = await waitForElement("chart-pie-pdf");
                const pie = await htmlToImage.toPng(pieChartElement);
                return { data: pie };
            },
        }),
        getImageByPath: builder.query<
            { imagePath: string; imageUri: string },
            {
                imageData: IImageData;
                size: ImageSizePaths;
                sharingId: string | null;
            }
        >({
            query: ({ imageData, size, sharingId }) => ({
                url: sharingId ? `/v2/global/reports/shared/${sharingId}/attachments/${imageData[size]}` : `/v2/global/reports/attachments/${imageData[size]}`,
                headers: {
                    "Content-Type": "image/jpeg",
                },
                responseHandler: getImageFromResponse(imageData[size]),
            }),
        }),
        trackReportVisit: builder.mutation<ReportResponse, { reportId: string; siteObjectId: string; userId: string }>({
            query: ({ reportId, siteObjectId, userId }) => ({
                url: `/v2/global/sites/${siteObjectId}/reports/${reportId}/visit/${userId}`,
                method: "PUT",
            }),
        }),
        trackReportUnvisit: builder.mutation<ReportResponse, { reportId: string; siteObjectId: string; userId: string }>({
            query: ({ reportId, siteObjectId, userId }) => ({
                url: `/v2/global/sites/${siteObjectId}/reports/${reportId}/unvisit/${userId}`,
                method: "PUT",
            }),
        }),
        trackTourSessionVisit: builder.mutation<
            ReportResponse,
            {
                reportId: string;
                siteObjectId: string;
                userId: string;
            }
        >({
            query: ({ reportId, siteObjectId, userId }) => ({
                url: `/v1/global/sites/${siteObjectId}/tour-sessions/${reportId}/visit/${userId}`,
                method: "PUT",
            }),
        }),
        trackTourSessionUnvisit: builder.mutation<
            ReportResponse,
            {
                reportId: string;
                siteObjectId: string;
                userId: string;
            }
        >({
            query: ({ reportId, siteObjectId, userId }) => ({
                url: `/v1/global/sites/${siteObjectId}/tour-sessions/${reportId}/unvisit/${userId}`,
                method: "PUT",
            }),
        }),
        getTranslatedText: builder.query<TranslationTextResponse, { text: string; targetLanguage: string }>({
            query: ({ text, targetLanguage }) => ({
                url: `/v2/bff/translate?text=${encodeURIComponent(text)}&targetLanguages=${targetLanguage}`,
            }),
        }),
        shareReport: builder.mutation<
            ShareReportRequest,
            { reportId: string; locationId: string; senderId: string; recipientEmails: string[]; languageCode: string }
        >({
            query: ({ reportId, locationId, senderId, recipientEmails, languageCode }) => ({
                url: `/v2/global/reports/shared`,
                method: "POST",
                body: {
                    senderId,
                    recipientEmails,
                    languageCode,
                    locationId,
                    reportId,
                },
            }),
        }),
        shareTourSession: builder.mutation<
            ShareReportRequest,
            {
                tourSessionId: string;
                locationId: string;
                senderId: string;
                recipientEmails: string[];
                languageCode: string;
            }
        >({
            query: ({ tourSessionId, locationId, senderId, recipientEmails, languageCode }) => ({
                url: `/v1/global/tour-sessions/shared`,
                method: "POST",
                body: {
                    senderId,
                    recipientEmails,
                    languageCode,
                    locationId,
                    tourSessionId,
                },
            }),
        }),
    }),
});

export const {
    useLazyFetchReportsToExportQuery,
    useLazyPrefetchReportsToExportQuery,
    useLazyFetchReportsFromSearchQuery,
    useLazyGetReportQuery,
    useLazyGetTourSessionQuery,
    useLazyGetRelatedReportQuery,
    useLazyAsyncGeneratePieChartQuery,
    useLazyGetSharedReportQuery,
    useLazyGetSharedTourSessionQuery,
    useTrackReportVisitMutation,
    useTrackReportUnvisitMutation,
    useTrackTourSessionVisitMutation,
    useTrackTourSessionUnvisitMutation,
    useLazyGetTranslatedTextQuery,
    useLazyGetImageByPathQuery,
    useShareReportMutation,
    useShareTourSessionMutation,
} = reportsApi;
