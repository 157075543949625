export const featureFlags = {
    assignmentReports: "experiment-assignment-reports",
    autotranslations: "experiment-autotranslations",
    inMemoryDemoClient: "entitlement-in-memory-demo-client",
    viewSchedule: "release-view-schedule",
    kpiSummary: "release-kpi-summary",
    sitesTimezones: "release-sites-timezones",
    sitePageTabsBarchart: "release-site-page-tabs-barchart",
    personalizeKpiSummary: "release-personalize-kpi-summary",
    summarySiteEventsNavigation: "release-summary-site-events-navigation",
    mobileGuardingTracking: "release-mg-tracking",
    trueDeviations: "release-true-deviations",
};
