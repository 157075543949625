import { useSelector } from "react-redux";

import { featureFlags } from "../data/featureFlags";
import FeatureFlagsSelectors from "../store/featureFlags/FeatureFlagsSelectors";

export const useFeatureFlag = (featureName: (typeof featureFlags)[keyof typeof featureFlags]): boolean => {
    const isFlagOn = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureName));

    // NOTE: Temp workaround to quickly enable site timezones on shared report view
    // since we enabled it on PROD for everyone
    // will be removed with https://secsisip.atlassian.net/browse/GLOB-5222
    return featureName === featureFlags.sitesTimezones || isFlagOn;
};
