import { IconType } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import { TextSummaryField } from "./TextSummaryField";
import { TrueDeviationsDisclaimer } from "./TrueDeviationsDisclaimer";

export const ExceptionQuestionField = ({ question, answer, isTrueException = false, shouldAutoTranslate = true }) => {
    const { t } = useTranslation();
    const isTrueDeviationsEnabled = useFeatureFlag(featureFlags.trueDeviations);

    if (!question) return <></>;

    const deviationTag = isTrueDeviationsEnabled && isTrueException ? { text: t("reportlist.deviations"), icon: "Deviations" as IconType } : undefined;

    return (
        <>
            {isTrueDeviationsEnabled && isTrueException && <TrueDeviationsDisclaimer />}
            <TextSummaryField
                key={question}
                fieldName={question}
                fieldValue={answer}
                tag={deviationTag}
                shouldAutoTranslate={shouldAutoTranslate}
                desktopDirection="row"
                mobileDirection="column"
                mobileValueAlignment="end"
            />
        </>
    );
};
